<div id="full-app-container">
  <div id="skip-link">
    <div role="list">
      <div role="listitem">
        <a tabIndex="0" (click)="goToContenu()" [href]="urlToGoToBlocContenu">{{ 'skip_menu_label' | translate  }}</a>
      </div>
    </div>
  </div>
  <header id="header-container">
    <antai-header [authService]="authService"></antai-header>
  </header>
  <main id="page-container">
    <!-- main app container -->
    <router-outlet></router-outlet>
  </main>
</div>
<footer id="footer-container">
  <antai-footer></antai-footer>
</footer>
