import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NonAdminAuthService } from '../core/auth/non-admin-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlaysService } from '../../../../common/src/lib/shared/services/overlays.service';

@Component({
  templateUrl: 'sign.component.html',
  styleUrls: ['sign.component.scss']
})

export class SignComponent implements OnInit {
  sgfDescription: string;
  signUpBtnClickEvent = new EventEmitter<Event>();

  constructor(
    private router: Router,
    public authenticationService: NonAdminAuthService,
    private translate: TranslateService,
    private overlaysService: OverlaysService
  ) {

    if (this.authenticationService.gestionSessionExpiree()) {
      this.overlaysService.addSnackBar(this.translate.instant('expired_session.title'), true);
      localStorage.clear();
    }
    // redirection vers la page home si déja connecté
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    translate.get('sign_page.sgf_description').subscribe((value: string) => {
      this.sgfDescription = value;
    });
  }

  ngOnInit() {
    this.signUpBtnClickEvent.subscribe(() => {
      this.goToRegister();
    });
  }

  goToRegister() {
    this.router.navigate(['/connexion/inscription/accueil']);
  }
}
