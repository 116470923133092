import { Component } from '@angular/core';
import { NonAdminAuthService } from './core/auth/non-admin-auth.service';
import { TranslationService } from '../../../common/src/lib/core/services/translation.service';
// @ts-ignore
import * as translation_non_admin from '../assets/i18n/fr.toml';
import { Router } from '@angular/router';


@Component({
  selector: 'antai-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  urlToGoToBlocContenu: string;

  constructor(
    public router: Router,
    private translationService: TranslationService,
    public authService: NonAdminAuthService) {
    translationService.InitLocalLanguage(translation_non_admin);
  }

  /**[Lien d'évitement]
   * Définition de l'url de destination
   */
  goToContenu() {
    if (this.router.url.includes('#page-container')) {
      this.urlToGoToBlocContenu = this.router.url;
    } else {
      this.urlToGoToBlocContenu = `${this.router.url}#page-container`;
    }
  }
}
