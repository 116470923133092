import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AntaiCommonModule } from '../../../common/src/lib/antai-common.module';
import { AppComponent } from './app.component';
import { SignRoutingModule } from './sign/sign-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { appInitializer } from '../../../common/src/lib/core/app-initializer';
import { NonAdminAuthService } from './core/auth/non-admin-auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NonAdminAuthJwtInterceptor } from './core/auth/non-admin-auth-jwt-interceptor';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AntaiCommonModule,
    SignRoutingModule,
    AppRoutingModule,
    TranslateModule.forRoot()
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [NonAdminAuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: NonAdminAuthJwtInterceptor, multi: true },
    // ****** "Provider" de mock : a n'utiliser qu'en DEV
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

