import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NonAdminAuthService } from '../auth/non-admin-auth.service';
import { AuthGuard } from '../../../../../common/src/lib/core/guards/auth.guard';

@Injectable({ providedIn: 'root' })
export class NonAdminAuthGuard extends AuthGuard {
  constructor(router: Router, nonAdminAuthService: NonAdminAuthService) {
    super(nonAdminAuthService, router);
  }
}
