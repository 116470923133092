<h1 class="page-title">{{ 'sign_page.title' | translate }}</h1>
<div fxLayout.gt-md="row">
  <div fxFlex.gt-md="19.9"></div>
  <p class="center" [innerHTML]="sgfDescription"></p>
  <div fxFlex.gt-md="19.9"></div>
</div>

<antai-home-message></antai-home-message>

<div class="cards-container"
     fxLayout.gt-md="row">


  <!------------------------------------------  card connexion -------------------------------------------->

  <antai-sign-in-card [authenticationService]="authenticationService" [color]="'shaded blue'" fxFlex.gt-md="58"></antai-sign-in-card>
  <div fxFlex="2"></div>

  <!------------------------------------------  card création de compte -------------------------------------------->

  <antai-card fxFlex.gt-md="40" [value]="{
        title: 'sign_page.sign_up.title',
        color: 'shaded white',
        isInline: true,
        footerWithBtn: {
          leftBtn: {
            color: 'blue',
            label: 'sign_page.sign_up.submit',
            clickEvent: {event: signUpBtnClickEvent}
          }
        },
        sectionsSizes: {header: 15, body: 0, footer: 15}
        }">
    <div id="sign-up-card-body" fxLayout="row">
      <div class="center" fxFlex="20.5">
        <img src="assets/icons/gear@3x.png" alt="">
      </div>
      <div fxFlex="75.5" id="sign-up-info-detail">
        <div>{{ 'sign_page.sign_up.detail' | translate }}</div>
      </div>
    </div>
  </antai-card>
</div>

<!------------------------------------------  informations légales -------------------------------------------->

<div fxLayout="row">
  <div fxFlex="0.8"><p>&#9656;</p></div>
  <div fxFlex="1"></div>
  <div fxFlex>
    <p id="legal-infos-title">{{ 'sign_page.legal.title' | translate }}</p>
    <p>
      {{ 'sign_page.legal.detail' | translate }}
      <a class="clickable-blue" target="_blank" rel="noopener noreferrer" href="https://www.legifrance.gouv.fr">{{ 'sign_page.legal.link' | translate }}</a>
    </p>
  </div>
</div>
