import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'projects/common/src/lib/core/components/not-found/not-found.component';
import { NonAdminAuthGuard } from './core/guards/non-admin-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'espace-entreprises', pathMatch: 'full'},
  {
    path: 'espace-entreprises',
    loadChildren: () => import('./company-space/company-space-routing.module').then(m => m.CompanySpaceRoutingModule),
    canActivate: [NonAdminAuthGuard]
  },
  { path: 'connexion/inscription/en-attente-de-validation',
    loadChildren: () => import('./sign/register/register-validation-pending/register-validation-pending-routing.module')
      .then(m => m.RegisterValidationPendingRoutingModule),
    canActivate: [NonAdminAuthGuard]
  },
  { path: 'mon-compte/mot-de-passe', loadChildren: () => import('projects/common/src/lib/shared/components/account-password/account-password.module').then(m => m.AccountPasswordModule) },
  { path: 'connexion/recuperation-de-compte', loadChildren: () => import('projects/common/src/lib/shared/components/account-password/account-password.module').then(m => m.AccountPasswordModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
