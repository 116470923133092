import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SgfAuthentificationService } from 'projects/common/src/lib/api/api-gateway-sgf/services/sgf-authentification.service';

import { FleetType } from 'projects/common/src/lib/shared/enums/fleet-type.enum';
import { AuthService } from 'projects/common/src/lib/core/auth/auth.service';
import { ConventionStatus } from '../../../../../common/src/lib/shared/enums/convention-status.enum';
import { StatutCompte } from '../../../../../common/src/lib/api/api-gestion-acces-sgf/models/statut-compte';

@Injectable({ providedIn: 'root' })
export class NonAdminAuthService extends AuthService {
  constructor(
    protected router: Router,
    protected sgfAuthentificationService: SgfAuthentificationService
  ) {
    super(sgfAuthentificationService, router);
  }
  redirectAccordingStatus() {
    if (this.currentUserValue.statutCompte === StatutCompte.Valide || this.currentUserValue.statutCompte === StatutCompte.Inactif) {
      this.router.navigate(['/']);
    } else if (this.currentUserValue.statutCompte === StatutCompte.EnAttenteFinalisation ||
      this.currentUserValue.statutCompte === StatutCompte.Refuse) {
      this.router.navigate(['/connexion/inscription/pieces-justificatives']);
    } else if (this.currentUserValue.statutCompte === StatutCompte.AControler) {
      this.router.navigate(['connexion/inscription/en-attente-de-validation']);
    } else {
      this.router.navigate(['/connexion']);
    }
  }

  redirectAccordingAccountType(url: string): boolean {
    if (this.currentUserValue) {
      if (url === '/espace-entreprises') {
        if (this.currentUserValue.statutCompte === StatutCompte.Valide || this.currentUserValue.statutCompte === StatutCompte.Inactif) {
          if (this.currentUserValue.typeSociete === FleetType.GRANDE_FLOTTE) {
            if (this.currentUserValue.statutConvention && this.currentUserValue.statutConvention !== ConventionStatus.REFUSE
             && this.currentUserValue.statutConvention !== ConventionStatus.DESACTIVE
            ) {
              this.router.navigate(['/espace-entreprises/mon-compte']);
            } else {
              this.router.navigate(['/espace-entreprises/ma-convention']);
            }
            return true;
          } else {
            this.router.navigate(['/espace-entreprises/designation']);
            return true;
          }
        } else {
          this.redirectAccordingStatus();
          return true;
        }
      }
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
