import { NgModule } from '@angular/core';
import { HomeMessageComponent } from './home-message.component';
import { CommonModule } from '@angular/common';
import { AntaiCardModule } from '../antai-card/antai-card.module';

@NgModule({
  declarations: [HomeMessageComponent],
  exports: [HomeMessageComponent],
  imports: [
    AntaiCardModule,
    CommonModule
  ]
})
export class HomeMessageModule {}
