import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { NonAdminAuthService } from 'projects/sgf/src/app/core/auth/non-admin-auth.service';

@Component({
  selector: 'antai-sign-in-card',
  templateUrl: './sign-in-card.component.html',
  styleUrls: ['./sign-in-card.component.scss']
})

/**
 * Bloc "Se Connecter"
 */
export class SignInCardComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  error: string;
  hide = true;
  signInBtnClickEvent = new EventEmitter<Event>();
  isRequestPending = false;

  @Input() color?: string;
  @Input() authenticationService: NonAdminAuthService;
  @ViewChild('formElement') formElement: FormGroupDirective;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) {
    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      accesCacher: ['', Validators.required]
    });
    this.loginForm.valueChanges.subscribe(() => {
      if ( this.error) {
        this.error = '';
      }
    });
  }

  ngOnInit() {
    this.signInBtnClickEvent.subscribe(() => {
      this.onSubmit();
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // getter pour faciliter l'accès aux champs du formulaire
  get f(): { [p: string]: AbstractControl } { return this.loginForm.controls; }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (!this.isRequestPending) {
      this.isRequestPending = true;
      // Recupération du token CSRF avant le login pour ne pas bloquer l'authentification
      this.authenticationService.initApplication();
      this.authenticationService.login(this.f.username.value, this.f.accesCacher.value)
        .pipe(
          finalize(() => {
            // réactiver le bouton
            this.isRequestPending = false;
          }),
        )
        .subscribe({
          error: err => {
            this.error = err.error.label;
          }
        });
    }
  }
}
