import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../../api/api-gestion-acces-sgf/services/message.service';
import { SgfMessageDto } from '../../../api/api-gestion-acces-sgf/models/sgf-message-dto';

/**
 * Composant permettant l'affichage d'un message sur la page d'accueil.
 * Ce message est récupéré depuis le BO.
 */
@Component({
  selector: 'antai-home-message',
  templateUrl: './home-message.component.html'
})
export class HomeMessageComponent  implements OnInit {

  value: SgfMessageDto;

  constructor( private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.messageService.recupererMessageAccueil()
      .subscribe((value: SgfMessageDto) => {
        if (value) {
          this.value = value;
        }
      });
  }
}
