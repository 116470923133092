import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SignComponent } from './sign.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { AntaiCardModule } from 'projects/common/src/lib/shared/components/antai-card/antai-card.module';
import { SignInCardModule } from 'projects/common/src/lib/shared/components/sign-in-card/sign-in-card.module';
import { NonAdminAuthGuard } from '../core/guards/non-admin-auth.guard';
import { HomeMessageModule } from '../../../../common/src/lib/shared/components/home-message/home-message.module';

/*
 Utilisation du lazy loading de module afin de charger les composants uniquement lors de leur affichage
 et non à l'ouverture de l'application.
 Pour ce faire les routes propres à un module son externalisées dans un autre module de routing.*/
/*
Exemple ci dessous pour le module d'inscription ("RegisterRoutingModule"):
Lors de la demande d'ouverture de la page home d'inscription, la gestion du routing est déléguée au module
de routing "RegisterRoutingModule" qui chargera les composants associés au chemin demandé. */


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    AntaiCardModule,
    SignInCardModule,
    HomeMessageModule,
    RouterModule.forChild([
      { path: 'connexion', component: SignComponent },
      { path: 'connexion/inscription/accueil',
        loadChildren: () => import('./register/register-routing.module').then(m => m.RegisterRoutingModule)
      },
      { path: 'connexion/inscription/parc-moyen',
        loadChildren: () => import('./register/register-routing.module').then(m => m.RegisterRoutingModule)
      },
      { path: 'connexion/inscription/grand-parc',
        loadChildren: () => import('./register/register-routing.module').then(m => m.RegisterRoutingModule)
      },
      {
        path: 'connexion/inscription/pieces-justificatives',
        loadChildren: () => import('./register/register-upload-pj/register-upload-pj-routing.module')
          .then(m => m.RegisterUploadPjRoutingModule),
        canActivate: [NonAdminAuthGuard]
      }
    ])
  ],
  declarations: [ SignComponent],
  exports: [
    RouterModule
  ]
})
export class SignRoutingModule { }
