import { Injectable } from '@angular/core';

import { NonAdminAuthService } from './non-admin-auth.service';
import { AuthJwtInterceptor } from '../../../../../common/src/lib/core/auth/auth-jwt-interceptor';

@Injectable()
export class NonAdminAuthJwtInterceptor extends AuthJwtInterceptor {
  constructor(nonAdminAuthService: NonAdminAuthService) {
    super(nonAdminAuthService);
  }
}
