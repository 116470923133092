<antai-card [value]="{
        title: 'sign_page.sign_in.title',
        color: color,
        isInline: true,
        footerWithBtn: {
          leftBtn: {
            color: 'blue',
            label: 'sign_page.sign_in.submit',
            clickEvent: {event: signInBtnClickEvent},
            disabled: loginForm.invalid || isRequestPending
          },
          links: [{label: 'sign_page.sign_in.pwd_lost', routePath: '/connexion/recuperation-de-compte'}]
        },
        sectionsSizes: {header: 15, body: 0, footer: 15}
        }">
  <p class="field-hint">* {{ 'field.mandatory' | translate }}</p>
  <form  class="with-input_form" #formElement="ngForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div fxLayout.gt-sm="row">
      <div fxFlex.gt-sm="48">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'sign_page.sign_in.login' | translate }}</mat-label>
          <input matInput type="text" formControlName="username" maxlength="254" required>
          <mat-error *ngIf="f.username.errors && f.username.errors.required">
            {{ 'field.error.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm></div>
      <div fxFlex.gt-sm="48">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'sign_page.sign_in.pwd' | translate }}</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="accesCacher" autocomplete="on" (keydown.enter)="onSubmit()" required>
          <mat-icon matSuffix (click)="hide = !hide" [className]="'cursor-pointer'">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="f.accesCacher.errors && f.accesCacher.errors.required">
            {{ 'field.error.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="error" class="label-error">
      <mat-error [innerHTML]="error | translate"></mat-error>
    </div>
  </form>
</antai-card>
